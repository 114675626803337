import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import { Button, Form, FormGroup, Input, Label, Row } from 'reactstrap';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    // state init
    this.state = {
      id: '',
      password: '',
      password_confirm: '',
      policy: false,
    };
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  handle_input_id = e => {
    this.setState({
      id: e.target.value,
    });
  };

  handle_input_password = e => {
    this.setState({
      password: e.target.value,
    });
  };

  handle_input_password_confirm = e => {
    this.setState({
      password_confirm: e.target.value,
    });
  };

  handle_checkbox_policy = e => {
    this.setState({
      policy: e.target.value,
    });
  };

  handle_login = e => {
    this.props.handle_login(this.state.id, this.state.password);
    e.preventDefault();
  };

  handle_signup = e => {
    this.props.handle_signup(
      this.state.id,
      this.state.password,
      this.state.password_confirm,
      this.state.policy,
    );
    e.preventDefault();
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input {...usernameInputProps} onChange={this.handle_input_id} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input
            {...passwordInputProps}
            onChange={this.handle_input_password}
          />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input
              {...confirmPasswordInputProps}
              onChange={this.handle_input_password_confirm}
            />
          </FormGroup>
        )}
        {/* 로그인시 아이디 저장 제외 */}
        {/* <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup> */}
        {this.isSignup ? (
          <Fragment>
            <Row className="mx-1 d-flex justify-content-between align-items-center">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={this.handle_checkbox_policy}
                  />{' '}
                  Agree the terms and policy
                </Label>
              </FormGroup>
              <Button
                color="primary"
                onClick={() => {
                  window.open(
                    `https://closed-bull-730.notion.site/Contail-CONTAIL-55ab7f529688422fa2e6b8758a153cc7`,
                    '_blank',
                    'noopener, noreferrer',
                  );
                }}
              >
                View
              </Button>
            </Row>
          </Fragment>
        ) : undefined}
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          // onClick={this.handleSubmit}
          onClick={this.isSignup ? this.handle_signup : this.handle_login}
        >
          {this.renderButtonText()}
        </Button>

        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
